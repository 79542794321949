import React from 'react'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
          &#169; 2025 Solar Engineering Consultants <br/>
          <a  href="/privacy-policy">Privacy Policy</a>
        </div>
      </footer>
    )
  }
}

export default Footer
